<script setup lang="ts">
import type { CmsElementBioCircle } from "~/composables/useCustom";
import SharedHeadline from "~/components/shared/SharedHeadline.vue";
import SharedTopline from "~/components/shared/SharedTopline.vue";
import { Carousel, Slide } from "vue3-carousel";
import { getCategoryRoute } from "@shopware/helpers";

const props = defineProps<{
  content: CmsElementBioCircle;
}>();

const settings = {
  itemsToShow: 5,
  itemsToScroll: 5,
  wrapAround: false,
  snapAlign: "center",
  autoplay: 5000,
  transition: 600,
  pauseAutoplayOnHover: true,
  breakpoints: {
    // 200px and up
    200: {
      itemsToShow: 3,
      itemsToScroll: 3,
      snapAlign: "center",
    },
    600: {
      itemsToShow: 5,
      itemsToScroll: 5,
      snapAlign: "center",
    },
  },
};

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const productSlider = ref(null);
const currentSlide = ref(0);

const { headlineText, toplineText, toplineType, headlineType, Slides } =
  props.content?.data;
</script>
<template>
  <div class="logo-slider-wrapper">
    <div class="container px-0 xl-px-5">
      <div class="mb-10 lg:mb-[3.75rem]">
        <SharedTopline
          :headline="toplineText"
          :headlineType="toplineType"
        ></SharedTopline>
        <SharedHeadline
          :headline="headlineText"
          :headlineType="headlineType"
        ></SharedHeadline>
      </div>
      <div
        id="ClientsCarousel"
        class="-mx-[2.5rem] [&>.slick-list>.slick-track]:flex [&>.slick-list>.slick-track]:items-center [&>.slick-list]:overflow-visible md:[&>.slick-list>.slick-track>.slick-slide.prev]:opacity-0 md:[&>.slick-list>.slick-track>.slick-slide]:transition-all md:[&>.slick-list>.slick-track>.slick-slide]:duration-75"
      >
        <Carousel v-bind="settings" ref="productSlider" v-model="currentSlide">
          <Slide v-for="slide of Slides" :key="slide.id">
            <div class="px-[2.2rem]">
              <NuxtLink
                v-if="slide.slideLink && slide.slideLink.type === 'internal'"
                :to="formatLink(getCategoryRoute(slide.slideLink.link))"
              >
                <NuxtImg
                  format="webp"
                  loading="lazy"
                  :src="slide.slideImage.media.url"
                  :alt="slide.slideImage.media.metaData.alt"
                  :height="slide.slideImage.media.metaData.height"
                  :width="slide.slideImage.media.metaData.width"
                  class="min-w-[100px]"
                />
              </NuxtLink>
              <NuxtLink
                v-if="slide.slideLink && slide.slideLink.type === 'external'"
                :to="slide.slideLink.url"
                target="_blank"
              >
                <NuxtImg
                  format="webp"
                  loading="lazy"
                  :src="slide.slideImage.media.url"
                  :alt="slide.slideImage.media.metaData.alt"
                  :height="slide.slideImage.media.metaData.height"
                  :width="slide.slideImage.media.metaData.width"
                  class="min-w-[100px]"
                />
              </NuxtLink>
              <NuxtImg
                v-if="!slide.slideLink"
                format="webp"
                loading="lazy"
                :src="slide.slideImage.media.url"
                :alt="slide.slideImage.media.metaData.alt"
                :height="slide.slideImage.media.metaData.height"
                :width="slide.slideImage.media.metaData.width"
                class="min-w-[100px]"
              />
            </div>
          </Slide>
        </Carousel>
      </div>
    </div>
  </div>
</template>
